import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from "react";
import { Route } from "react-router-dom";

const NoNavbarRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Container component="main" maxWidth="xl" disableGutters>
          <CssBaseline />
          <Component {...props} />
        </Container>;
      }}
    />
  );
};

export default NoNavbarRoute;
