import { useSnackbar } from "notistack";
import { Typography, Chip, Button, Grid, Link } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ReczeeCompanyWhiteNavbar from "./ReczeeCompanyWhiteNavbar";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: "150px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      marginLeft: 0,
    },
  },
  jobTypeContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: theme.spacing(2),
    justifyContent: "center",
    gap: theme.spacing(2),
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  jobTitleHeader: {
    color: theme.palette.secondary.contrastText,
    fontWeight: "bolder",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "30px",
    },
  },
  jobDetailsRow: {
    color: theme.palette.secondary.contrastText,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6px",
  },
  jobTypeItems: {
    marginRight: "8px",
    fontWeight: "bolder",
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  allJobsButton: {
    marginRight: theme.spacing(2),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.99,
    },
  },
  companyLogoImage: {
    // marginLeft: theme.spacing(4),
  },
  jobsNavbar: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    color: theme.palette.secondary.contrastText,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px 80px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "30px",
      alignItems: "flex-start",
    },
  },
  emptyNavbarWithLogo: {
    height: "80px",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  chipOutlined: {
    color: theme.palette.secondary.contrastText,
    borderColor: theme.palette.secondary.contrastText,
    fontSize: "12px",
    height: "28px",
    backgroundColor: "transparent",
  },
  jobInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    color: theme.palette.secondary.contrastText,
    gap: "5px",
    fontSize: "0.95rem",
    fontWeight: "500",
  },
  viewAllOpeningsButton: {
    backgroundColor:
      theme.palette.primary.main === theme.palette.secondary.main
        ? "#ffffff"
        : theme.palette.primary.main,
    color:
      theme.palette.primary.main === theme.palette.secondary.main
        ? "#2f2f2f"
        : theme.palette.primary.contrastText,
    textTransform: "none",
    padding: "8px 28px",
    fontSize: "16px",
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "14px",
      marginTop: "20px",
    },
    "&:hover": {
      backgroundColor:
        theme.palette.primary.main === theme.palette.secondary.main
          ? "#ffffffdd"
          : theme.palette.primary.main + "dd",
    },
  },
  socialIconsGrid: {
    width: "auto",
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  socialMediaIcon: {
    color: theme.palette.secondary.contrastText,
  },
}));

export default function ReczeeJobsPageNavbar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(
    props.companyDetails && props.companyDetails.customStyle
      ? props.companyDetails.customStyle
      : {}
  );
  const scrollToOpenPositions = () => {
    const element = document.getElementById("submit-application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const jobURL = window.location.href.split("?")[0];

  const handleCopyToClipboard = () => {
    var textField = document.createElement("textarea");

    textField.innerHTML = jobURL;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    enqueueSnackbar(
      "Application URL copied to clipboard!",
      reczeeAlertParams("success")
    );
  };

  return (
    <div className={classes.root}>
      <ReczeeCompanyWhiteNavbar {...props} />
      <AppBar position="static" className={classes.jobsNavbar}>
        <Toolbar className={classes.toolbar}>
          {props.jobDetails.department ? (
            <Chip
              label={props.jobDetails.department.title}
              variant="outlined"
              classes={{ outlined: classes.chipOutlined }}
            />
          ) : (
            ""
          )}
          <Typography
            component="h1"
            variant="h4"
            className={classes.jobTitleHeader}
          >
            {props.jobDetails.designation}
          </Typography>
          <div className={classes.jobTypeContainer}>
            <Typography variant="subtitle2" className={classes.jobInfo}>
              <LocationOnOutlinedIcon fontSize="small" />
              {props.jobDetails.location
                ? `${props.jobDetails.location.city}, ${props.jobDetails.location.country}`
                : null}
            </Typography>
            {props.jobDetails.experience_range ? (
              <Typography variant="subtitle2" className={classes.jobInfo}>
                <BusinessCenterOutlinedIcon fontSize="small" />
                {props.jobDetails.experience_range?.[0]}-
                {props.jobDetails.experience_range?.[1]} years
              </Typography>
            ) : (
              ""
            )}
            <Typography variant="subtitle2" className={classes.jobInfo}>
              <ApartmentOutlinedIcon fontSize="small" />
              {props.jobDetails.job_type_display}
            </Typography>
          </div>
          <div className={classes.socialMedia}>
            <Grid container spacing={2} className={classes.socialIconsGrid}>
              <Grid item>
                <Link
                  target="_blank"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    jobURL
                  )}`}
                >
                  <LinkedInIcon
                    fontSize="20px"
                    className={classes.socialMediaIcon}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  target="_blank"
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    jobURL
                  )}`}
                >
                  <TwitterIcon
                    fontSize="20px"
                    className={classes.socialMediaIcon}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    jobURL
                  )}`}
                >
                  <FacebookIcon
                    fontSize="20px"
                    className={classes.socialMediaIcon}
                  />
                </Link>
              </Grid>
              <Grid item>
                <FileCopyIcon
                  fontSize="18px"
                  className={classes.socialMediaIcon}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          </div>
        </Toolbar>
        {props.open_for_careers_page && (
          <Button
            type="submit"
            variant="contained"
            className={classes.viewAllOpeningsButton}
            size={"medium"}
            onClick={scrollToOpenPositions}
          >
            Apply Now
          </Button>
        )}
      </AppBar>
    </div>
  );
}
