import React from "react";
import { Button, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CareersPageCarousel from "./CareersPageCarousel";
import { CAREERS_PAGE_CDN_BASE_URL } from "../../constants";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const bgURL = `/react-static/companies/reczee-demo/hero-section-image.jpg`;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    heroSection: {
      justifyContent: "center !important",
      alignItems: "center",
      background: `url(${bgURL}) no-repeat center center / cover`,
      [theme.breakpoints.down("sm")]: {
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      background: "linear-gradient(180deg, #000000 0%, #000000 100%)",
      opacity: 0.8,
      filter: "blur(4px)",
      zIndex: "0",
    },
    heroSectionContent: {
      position: "relative",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      width: "55%",
      [theme.breakpoints.down("md")]: {
        width: "60%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    careersPageSection: {
      minHeight: "500px",
      width: "100%",
      display: "flex",
      position: "relative",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
    },
    viewAllOpeningsButton: {
      textTransform: "none",
      padding: "8px 28px",
      marginTop: theme.spacing(1),
      fontSize: "16px",
      fontWeight: "bolder",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    mainHeading: {
      fontWeight: "bolder",
      fontSize: "48px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "35px",
      },
    },
    subHeading: {
      fontSize: "20px",
      color: "white",
      padding: "10px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    benefitsSection: {
      backgroundColor: theme.palette.secondary.main + "04",
    },
    careersPageSection91: {
      padding: theme.spacing(2),
      display: "flex",
      paddingTop: theme.spacing(4),
      justifyContent: "start",
      flexDirection: "column",
      // alignItems: "center",
      backgroundColor: "white",
      position: "relative",
      zIndex: 1,
    },
    youtubeVideo: {
      aspectRatio: "16 / 9",
      width: "100%",
      maxWidth: "800px",
      margin: "64px auto 64px",
      padding: "14px",
      boxSizing: "border-box",
      overflow: "hidden",
      borderRadius: "10px",
      boxShadow: "none",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      "& iframe": {
        width: "100%",
        height: "100%",
        border: "none",
      },
    },
  };
});

function LifeAtReczeeDemo() {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection91}>
      <Typography
        component="h3"
        variant="h4"
        style={{ paddingTop: "16px", paddingBottom: "8px", fontWeight: "700" }}
      >
        Life at Reczee
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ color: "#666666", marginBottom: "20px" }}
      >
        Embrace challenges and celebrate successes in an environment that values
        your contributions.
      </Typography>
      <CareersPageCarousel
        images={[
          `/react-static/companies/reczee-demo/life-1.jpg`,
          `/react-static/companies/reczee-demo/life-2.jpg`,
          `/react-static/companies/reczee-demo/life-3.jpg`,
          `/react-static/companies/reczee-demo/life-4.jpg`,
          `/react-static/companies/reczee-demo/life-5.jpg`,
        ]}
      />
    </div>
  );
}

export default function ReczeeDemoCareersPage(props) {
  const classes = useStyles();

  const scrollToOpenPositions = () => {
    const element = document.getElementById("job-openings-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className={`${classes.heroSection} ${classes.careersPageSection} `}>
        <div className={classes.overlay}></div>
        <div className={classes.heroSectionContent}>
          <Typography
            component="h1"
            variant="h4"
            className={classes.mainHeading}
          >
            Join our team
          </Typography>
          <Typography variant="caption" className={classes.subHeading}>
            Streamlining age-old and inefficient recruitment activities
          </Typography>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.viewAllOpeningsButton}
          size={"medium"}
          onClick={scrollToOpenPositions}
        >
          View open jobs
        </Button>
      </div>
      <LifeAtReczeeDemo companyDetails={props.companyDetails} />
      <Card className={classes.youtubeVideo}>
        <iframe
          src="https://www.youtube.com/embed/0VS9ksooObY?si=Lebr8xuplU9ex01I"
          title="YouTube video"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Card>
    </>
  );
}
