import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EmojiObjectsSharpIcon from "@material-ui/icons/EmojiObjectsSharp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PeopleIcon from "@material-ui/icons/People";
import StarIcon from "@material-ui/icons/Star";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";

const useStyles = makeStyles((theme) => ({
  careersPageSection: {
    minHeight: "500px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main + "09", //'white'
  },
  coreValueItemContainer: {
    padding: theme.spacing(2),
    // minHeight: '180px'
  },
  iconContainerDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  valueIconContainer: {
    borderRadius: "50%",
    backgroundColor: "#00000011",
    width: "50px",
    "& svg": {
      fontSize: "1.7rem",
    },
    display: "flex",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
  coreValueGridContainer: {
    padding: "20px 60px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 40px !important",
    },
  },
  itemTypeHeading: {
    // marginBottom: theme.spacing(2)
  },
  itemDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function DefaultOurValues(props) {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection}>
      <Typography component="h3" variant="h4" style={{ padding: "16px" }}>
        Our values
      </Typography>
      Coming soon!
    </div>
  );
}

function StopStalkValues(props) {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection}>
      <Typography component="h3" variant="h4" style={{ padding: "16px" }}>
        Our values
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.coreValueGridContainer}>
          <Paper className={classes.coreValueItemContainer}>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Customer obsession
            </Typography>
            <Divider className={classes.itemDivider} />
            We love our customers and solving their pain points is our bread and
            butter! Most of the times we do more than just the expected.
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coreValueGridContainer}>
          <Paper className={classes.coreValueItemContainer}>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Hustle
            </Typography>
            <Divider className={classes.itemDivider} />
            We hustle! Every day, every hour, every minute, every second! If
            plan A doesn't work, we take plan B, ... Alphabets are usually less!
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coreValueGridContainer}>
          <Paper className={classes.coreValueItemContainer}>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Get it done
            </Typography>
            <Divider className={classes.itemDivider} />
            We are doers. We don't complaint, we understand what needs to be
            done and deliver!
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coreValueGridContainer}>
          <Paper className={classes.coreValueItemContainer}>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Simple is good
            </Typography>
            <Divider className={classes.itemDivider} />
            Simplicity is awesome! Understanding the problems at hand and coming
            up with simple solutions is what we strive for.
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function SapientValues(props) {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection}>
      <Typography component="h3" variant="h4" style={{ padding: "16px" }}>
        Our values
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#3e9bff44" }}
              >
                <EmojiObjectsSharpIcon
                  fontSize="large"
                  style={{ color: "#3e9bff" }}
                />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Biased for Innovation
            </Typography>
            <Divider className={classes.itemDivider} />
            Our innovations enabled us to create the ultimate test code writing
            solution for developers. But this is just the beginning - we're
            constantly pushing the boundaries to ensure that tomorrow's product
            is even more groundbreaking than today's.
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#f9b44544" }}
              >
                <CheckCircleIcon
                  fontSize="large"
                  style={{ color: "#f9b445" }}
                />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              We Are Transparent
            </Typography>
            <Divider className={classes.itemDivider} />
            We have a culture of openness, honesty and trust, which fosters
            better communication, collaboration, and problem-solving. This helps
            up create a shared understanding to work towards the same goals and
            stay on track towards success.
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#3bc16844" }}
              >
                <PeopleIcon fontSize="large" style={{ color: "#3bc168" }} />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              We Play Like Team-sport
            </Typography>
            <Divider className={classes.itemDivider} />
            We have embraced a shared vision, values, and goals that drive us
            towards success. We play like a team-sport, we build trust,
            communication, and collaboration that allows us to overcome
            obstacles and achieve our objectives.
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#7e48df44" }}
              >
                <VpnKeyIcon fontSize="large" style={{ color: "#7e48df" }} />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Every Experience Matters
            </Typography>
            <Divider className={classes.itemDivider} />
            Customers, employees, users, partners – we believe everyone’s
            experience must be a stellar reflection of who we are. It guides our
            decision making process.
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#f6687244" }}
              >
                <FavoriteIcon fontSize="large" style={{ color: "#f66872" }} />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Committed For Customers
            </Typography>
            <Divider className={classes.itemDivider} />
            We put our customers first and create products and services that
            exceed their expectations.
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.coreValueGridContainer}
        >
          <Paper className={classes.coreValueItemContainer}>
            <div className={classes.iconContainerDiv}>
              <div
                className={classes.valueIconContainer}
                style={{ backgroundColor: "#ffd82544" }}
              >
                <StarIcon fontSize="large" style={{ color: "#ffd825" }} />
              </div>
            </div>
            <Typography
              component="h3"
              variant="h5"
              className={classes.itemTypeHeading}
            >
              Aim for stars
            </Typography>
            <Divider className={classes.itemDivider} />
            We aim for the stars, break new ground, and blaze a trail for others
            to follow. We aspire to make our mark on the world, and leave a
            legacy that will inspire generations to come.
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default function OurValues(props) {
  if (!props || !props.companyDetails) {
    return <DefaultOurValues {...props} />;
  }

  switch (props.companyDetails.slug) {
    case "company75":
    case "stopstalk":
      return <StopStalkValues {...props} />;
    case "sapient":
      return <SapientValues {...props} />;
    default:
      return "";
  }
}
