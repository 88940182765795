import React from "react";
import { ReczeeAutoComplete } from "../commons/ReczeeInputField";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { postRequest } from "../../utils/httpHelpers";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { Link } from "react-router-dom";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import amplitudeLogger, { SCREENO_DASHBOARD_PAGE } from "../../utils/amplitude";

const useStyles = makeStyles((theme) => ({
  jobsField: {
    width: "70%",
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

function getFieldName(externalAtsProvider) {
  return externalAtsProvider === "lever" ? "text" : "name";
}

function ScreenoIntegrationsFetchForm({ externalAtsProvider }) {
  const classes = useStyles();
  const [job, setJob] = React.useState(null);
  const [stages, setStages] = React.useState([]);
  const [sources, setSources] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    amplitudeLogger.logEvent("SCREENO_FORM_SUBMIT", {
      page: SCREENO_DASHBOARD_PAGE,
      tab: "integrations",
      provider: externalAtsProvider,
      stages: stages.length,
      sources: sources.length,
    });

    if (!job["id"]) {
      enqueueSnackbar("Please select a job!", reczeeAlertParams("error"));
      return;
    }

    setIsSubmitting(true);
    postRequest(
      `screeno/integrations/fetch-ats-candidates`,
      {},
      {
        ats_job_id: job["id"],
        stages: stages.map((stage) => stage["id"]),
        sources: sources.map((source) =>
          externalAtsProvider === "lever" ? source["text"] : source["id"]
        ),
        external_ats_provider: externalAtsProvider,
      },
      (data) => {
        enqueueSnackbar(
          "Fetching task submitted, will be ready in seconds!",
          reczeeAlertParams("success")
        );
        setJob(null);
        setStages([]);
        setSources([]);
        setIsSubmitting(false);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  return (
    <>
      {externalAtsProvider === "none" ? (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            component={Link}
            startIcon={<SwapHorizIcon />}
            onClick={() => {
              amplitudeLogger.logEvent("SCREENO_CONNECT_ATS_BUTTON", {
                page: SCREENO_DASHBOARD_PAGE,
                tab: "integrations",
              });
            }}
            to={"/screeno/integrations"}
          >
            CONNECT ATS
          </Button>
        </div>
      ) : (
        <>
          <ReczeeAutoComplete
            size="small"
            single={true}
            variant="standard"
            label="Jobs"
            onChange={(_, value) => {
              setJob(value);
              setSources([]);
              setStages([]);
            }}
            className={classes.jobsField}
            value={job}
            dataFetchPath="screeno/integrations/list-jobs"
            dataFetchParams={{ ats_provider: externalAtsProvider }}
            reRenderDependency={[]}
            responseMapper={(response) => response.jobs}
            getOptionLabel={(option) =>
              option[getFieldName(externalAtsProvider)]
            }
          />
          <ReczeeAutoComplete
            size="small"
            single={false}
            className={classes.jobsField}
            variant="standard"
            label="Stage"
            onChange={(_, value) => {
              setStages(value);
            }}
            value={stages}
            dataFetchPath="screeno/integrations/list-stages"
            reRenderDependency={[job]}
            dataFetchParams={{
              ats_job_id: job == null ? null : job["id"],
              ats_provider: externalAtsProvider,
            }}
            responseMapper={(response) => response.stages}
            getOptionLabel={(option) =>
              option[getFieldName(externalAtsProvider)]
            }
          />
          <ReczeeAutoComplete
            size="small"
            single={false}
            variant="standard"
            label="Source"
            onChange={(_, value) => {
              setSources(value);
            }}
            className={classes.jobsField}
            value={sources}
            dataFetchPath="screeno/integrations/list-sources"
            dataFetchParams={{ ats_provider: externalAtsProvider }}
            reRenderDependency={[]}
            responseMapper={(response) => response.sources}
            getOptionLabel={(option) =>
              option[getFieldName(externalAtsProvider)]
            }
          />
          <Button
            startIcon={
              <img
                width="20"
                alt="star"
                src={getStaticResourceUrl(
                  isSubmitting ? "/ai-stars-disabled.svg" : "/ai-stars.svg"
                )}
              />
            }
            variant="contained"
            color="primary"
            size="medium"
            className={classes.submitButton}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "PLEASE WAIT..." : "SUBMIT"}
          </Button>
        </>
      )}
    </>
  );
}

export default React.memo(ScreenoIntegrationsFetchForm);
