import React from "react";
import { ReczeeDialog } from "../commons/ReczeeDialog";
import { Step, StepContent, StepLabel, Stepper, Link } from "@material-ui/core";
import { postRequest } from "../../utils/httpHelpers";
import { Button, Grid } from "@material-ui/core";
import {
  ReczeeAutoComplete,
  ReczeeInputField,
} from "../commons/ReczeeInputField";
import { useTheme } from "@material-ui/core/styles";
import { Brightness1Outlined, Brightness1Rounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import amplitudeLogger, {
  SCREENO_INTEGRATIONS_PAGE,
} from "../../utils/amplitude";

const useStyles = makeStyles((theme) => ({
  stepper: {
    paddingLeft: 0,
    width: "220px",
    height: "fit-content",
    alignSelf: "center",
  },
  stepContent: {
    height: "35px",
  },
  listItemContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-1),
    "& li": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "16px",
    },
  },
  formContainer: {
    overflowY: "auto",
    maxHeight: "200px",
  },
  formFooter: {
    marginTop: "18px",
    justifyContent: "flex-end",
  },
  stepLabel: {
    "& .MuiStepLabel-completed": {
      color: theme.palette.success.main,
      fontWeight: "bold",
    },
  },
  gridContainer: {
    flexGrow: 1,
    height: "400px",
    overflow: "scroll",
    width: "min-content",
    paddingTop: "10px",
    paddingLeft: "25px",
    borderLeft: "1px solid #BDBDBD",
  },
  displayNoneIcon: {
    display: "none",
  },
  permissionListItem: {
    display: "flex",
  },
  greenIcon: {
    color: "green",
    marginLeft: theme.spacing(0.5),
  },
  redIcon: {
    color: "red",
    marginLeft: theme.spacing(0.5),
  },
}));

function isAllTrue(greenhouseTestStatus) {
  if (greenhouseTestStatus === null) return false;

  return Object.values(greenhouseTestStatus).every((value) => value === true);
}

function countTrue(greenhouseTestStatus) {
  if (greenhouseTestStatus === null) return 0;

  var count = 0;
  for (var key in greenhouseTestStatus) {
    if (greenhouseTestStatus[key] === true) {
      count++;
    }
  }
  return count;
}

function GreenhouseSetupModal({
  greenhouseModalOpen,
  setGreenhouseModalOpen,
  greenHouseConnectedStatus,
  setGreenHouseConnectedStatus,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [greenhouseTestStatus, setGreenhouseTestStatus] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [harvestApiKey, setHarvestApiKey] = React.useState("");
  const [testInProgress, setTestInProgress] = React.useState(false);
  const theme = useTheme();
  const greenhouseStepperItems = [
    {
      label: "Harvest API Key",
      submitClick: (e) => {
        setActiveStep((prevState) => prevState + 1);
      },
      submitDisabled: harvestApiKey === "",
      submitButtonLabel: "Next",
    },
    {
      label: "Setup permissions",
      submitClick: (e) => {
        if (isAllTrue(greenhouseTestStatus)) {
          setActiveStep((prevState) => prevState + 1);
        } else {
          setTestInProgress(true);
          // Call backend to set permissions
          postRequest(
            `screeno/test-greenhouse-connection`,
            {},
            {
              harvest_api_key: harvestApiKey,
            },
            (data) => {
              amplitudeLogger.logEvent("SCREENO_INTEGRATION_TEST_PERMISSIONS", {
                provider: "greenhouse",
                page: SCREENO_INTEGRATIONS_PAGE,
                data: `${countTrue(data)}/${Object.keys(data).length}`,
              });

              setTestInProgress(false);
              setGreenhouseTestStatus(data);
            },
            (errorMessages, statusCode) => {
              setTestInProgress(false);
            }
          );
        }
      },
      submitDisabled: testInProgress,
      submitButtonLabel:
        greenhouseTestStatus && isAllTrue(greenhouseTestStatus)
          ? "Next"
          : testInProgress
          ? "Validating..."
          : greenhouseTestStatus === null
          ? "Validate"
          : "Re-validate",
    },
    {
      label: "On behalf of user",
      submitClick: (e) => {
        if (selectedUser) {
          setTestInProgress(true);
          postRequest(
            `screeno/greenhouse/save-connection`,
            {},
            {
              harvest_api_key: harvestApiKey,
              user: selectedUser,
            },
            (data) => {
              setGreenHouseConnectedStatus("connected");
              setGreenhouseModalOpen(false);
              setTestInProgress(false);
            },
            (errorMessages, statusCode) => {
              setTestInProgress(false);
              if (typeof errorMessages === "string") {
                enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
              } else {
                enqueueSnackbar(
                  "Something went wrong!",
                  reczeeAlertParams("error")
                );
              }
            }
          );
        } else {
          enqueueSnackbar(
            "Please select a user before saving",
            reczeeAlertParams("error")
          );
        }
      },
      submitDisabled: testInProgress,
      submitButtonLabel: testInProgress ? "Please wait..." : "Complete Setup",
    },
  ];

  const getTestStatusIcon = (keyName) => {
    if (greenhouseTestStatus === null) return "";

    return greenhouseTestStatus[keyName] ? (
      <CheckIcon fontSize="small" className={classes.greenIcon} />
    ) : (
      <ClearIcon fontSize="small" className={classes.redIcon} />
    );
  };

  return (
    <ReczeeDialog
      title="Connect Greenhouse"
      description="Seamlessly tag your applicants on Greenhouse with Reczee Screeno!"
      open={greenhouseModalOpen}
      onClose={() => setGreenhouseModalOpen(false)}
      content={
        <>
          <form noValidate onSubmit={() => {}}>
            <Grid container style={{ minHeight: "350px" }}>
              <Stepper
                orientation="vertical"
                activeStep={activeStep}
                className={classes.stepper}
              >
                {greenhouseStepperItems.map((step, index) => {
                  const active = index === activeStep;
                  const completed = index < activeStep;

                  return (
                    <Step key={index} active={active} completed={completed}>
                      <StepLabel
                        StepIconComponent={
                          completed || active
                            ? Brightness1Rounded
                            : Brightness1Outlined
                        }
                        className={classes.stepLabel}
                        StepIconProps={{
                          style: {
                            color: completed
                              ? theme.palette.success.main
                              : active
                              ? theme.palette.primary.main
                              : "inherit",
                          },
                        }}
                      >
                        {step.label}
                      </StepLabel>
                      {index < greenhouseStepperItems.length - 1 && (
                        <StepContent className={classes.stepContent} />
                      )}
                    </Step>
                  );
                })}
              </Stepper>
              <Grid className={classes.gridContainer}>
                {activeStep === 0 && (
                  <div>
                    <ReczeeInputField
                      label="Harvest API Key"
                      name="api_key"
                      autoFocus={true}
                      fullWidth={true}
                      value={harvestApiKey}
                      onChange={(e) => {
                        setHarvestApiKey(e.target.value);
                        setGreenhouseTestStatus(null);
                      }}
                      variant="outlined"
                      className={classes.fullWidthContainer}
                    />
                    <ol className={classes.listItemContainer}>
                      <li>
                        Login to Greenhouse as a <b>Site Admin</b>
                      </li>
                      <li>
                        Open the{" "}
                        <Link href="https://app.greenhouse.io/configure/dev_center/credentials">
                          API Credentials ↗
                        </Link>{" "}
                        page.
                      </li>
                      <li>
                        Click on <b>Create new API key</b>
                      </li>
                      <li>
                        Select API type - <b>Harvest</b>,<br />
                        Partner - <b>Reczee Screeno</b>,<br />
                        Description - <b>Reczee Screeno</b>
                      </li>
                      <li>
                        Copy the API key generated and paste it on the above
                        input field
                      </li>
                      <li>
                        Click on <b>Next</b> and setup the required permissions.
                      </li>
                    </ol>
                  </div>
                )}
                {activeStep === 1 && (
                  <div>
                    <div style={{ marginTop: "8px" }}>
                      <b>Assign the following permissions </b>
                      {greenhouseTestStatus !== null && (
                        <>
                          ({countTrue(greenhouseTestStatus)} match out of{" "}
                          {Object.keys(greenhouseTestStatus).length})
                        </>
                      )}
                    </div>
                    <ul>
                      <li>
                        Applications
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - Retrieve application
                              {getTestStatusIcon("get|||retrieve-application")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List applications
                              {getTestStatusIcon("get|||list-applications")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Candidate
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List candidates
                              {getTestStatusIcon("get|||list-candidates")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - Retrieve candidate
                              {getTestStatusIcon("get|||retrieve-candidate")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              PATCH - Update candidate
                              {getTestStatusIcon("patch|||update-candidate")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Tags
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              DELETE - Remove tag from candidate
                              {getTestStatusIcon(
                                "delete|||remove-tag-from-candidate"
                              )}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List tags applied to candidate
                              {getTestStatusIcon("get|||list-candidate-tags")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List Candidate Tags
                              {getTestStatusIcon("get|||list-account-tags")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              POST - Add new candidate tags
                              {getTestStatusIcon("post|||add-account-tag")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              DELETE - Remove candidate tags
                              {getTestStatusIcon("delete|||remove-account-tag")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              PUT - Add a candidate tag
                              {getTestStatusIcon("put|||assign-candidate-tag")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List users
                              {getTestStatusIcon("get|||list-users")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Jobs
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - Retrieve Job
                              {getTestStatusIcon("get|||retrieve-job")}
                            </div>
                            <div className={classes.permissionListItem}>
                              GET - List jobs
                              {getTestStatusIcon("get|||list-jobs")}
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        Job stages
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List job stages
                              {getTestStatusIcon("get|||list-job-stages")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Sources
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List Sources
                              {getTestStatusIcon("get|||list-sources")}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Custom fields
                        <ul>
                          <li>
                            <div className={classes.permissionListItem}>
                              GET - List Custom Fields
                              {getTestStatusIcon("get|||list-custom-fields")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              POST - Create Custom Fields
                              {getTestStatusIcon("post|||create-custom-field")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              PATCH - Update Custom Field
                              {getTestStatusIcon("patch|||update-custom-field")}
                            </div>
                          </li>
                          <li>
                            <div className={classes.permissionListItem}>
                              DELETE - Delete Custom Field
                              {getTestStatusIcon(
                                "delete|||delete-custom-field"
                              )}
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
                {activeStep === 2 && (
                  <div>
                    <ReczeeAutoComplete
                      className={classes.filterInputField}
                      size="medium"
                      onChange={(_, values) => {
                        setSelectedUser(values);
                      }}
                      value={selectedUser}
                      responseMapper={(response) => response.users}
                      label="Perform actions on behalf of"
                      helperText="Used for auditing purposes by Greenhouse"
                      dataFetchParams={{ harvest_api_key: harvestApiKey }}
                      dataFetchPath={`screeno/integrations/greenhouse/list-users`}
                      single={true}
                      getOptionLabel={(option) => option.name}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formFooter}>
              {activeStep !== 0 && (
                <Grid item>
                  <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    onClick={() => setActiveStep((prevState) => prevState - 1)}
                  >
                    Back
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  type="submit"
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={greenhouseStepperItems[activeStep].submitDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    greenhouseStepperItems[activeStep].submitClick(e);
                  }}
                >
                  {greenhouseStepperItems[activeStep].submitButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      }
    />
  );
}

export default React.memo(GreenhouseSetupModal);
